@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/elevations' as elevations;
@use '../../scss/screenSizes' as screenSizes;

.search-by {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;

  align-items: center;

  .vertical-search-divider {
    position: relative;
    transform: rotate(90deg);

    width: 3rem;
    right: 5rem;
  }

  .input-stack {
    background-color: colors.$surface-500;
    display: flex;
    flex-direction: row;
    border-radius: 50%;

    position: relative;

    input {
      width: 18.75rem;
      border: 1px solid colors.$surface-500;
      border-radius: 0% 0% 0% 0%;
    }

    .filter-dropdown {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 8.25rem;
      border: 1px solid colors.$surface-500;
      border-radius: 0.5rem 0% 0% 0.5rem;
      padding: 10;
      margin: 10;

      .form-field {
        gap: 0.5rem;
      }
    }

    .search-svg-wrapper {
      position: relative;

      .search-toggle-visibility {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        width: 2.75rem;
        height: 100%;
        background: transparent;
        background-color: transparent;
        border: none;
        border-radius: 0.5rem;
      }

      .search-border {
        border-left: 1px solid colors.$surface-500;
      }

      .icon-holder {
        border-radius: 0% 0.5rem 0.5rem 0%;
        width: 0.625rem;
      }
    }
  }

  @include screenSizes.tablet {
    .search-by-label {
      @include fonts.caption1;
    }

    .input-stack {
      .search-svg-wrapper {
        .search-toggle-visibility {
          width: 2.4rem;
        }
      }
    }
  }

  @include screenSizes.mobile {
    gap: 0.5rem;

    .search-by-label {
      display: none;
    }

    .input-stack {
      width: 100%;

      .filter-dropdown {
        padding: 0.25rem;
        width: 30%;

        .form-field {
          gap: 0.25rem;

          P {
            @include fonts.caption2;
          }

          svg {
            width: 0.5rem;
            height: 0.5rem;
          }
        }
      }

      .validated-input-label {
        width: 65%;

        input {
          width: 100%;
          line-height: 1rem;
        }
      }

      .search-svg-wrapper {
        .icon-holder {
          width: 1.2rem;
        }

        .search-svg {
          width: 0.75rem;
          height: 0.75rem;
          right: 1rem;
        }
      }
    }
  }
}