@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.filter-by {
  display: flex;
  flex-direction: column;
  width: 100%;

  .label {
    display: flex;
    padding-bottom: 1rem;
    justify-content: space-between;

    .reset-filter-row {
      display: flex;
    }

    .reset-filters-button {
      cursor: pointer;
      color: colors.$primary-blue-400;
      display: flex;
      gap: 0.25rem;
      margin-left: 0.5rem;

      svg {
        fill: colors.$primary-blue-400;
      }
    }

    .download-button-row {
      display: flex;
      gap: 1rem;

      .download-report {
        pointer-events: auto;
        cursor: pointer;

        &.disabled {
          pointer-events: auto;
          opacity: 0.6;
          color: colors.$text-200;
        }
      }
    }
  }

  .button-row {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    width: 100%;

    .filters {
      display: flex;
      gap: 0.5rem;
      width: 100%;
      max-width: 50rem;

      .filter-category{
        width: inherit;
      }
    }

    .date-input {
      width: 13rem;
    }

    .date-range-input {
      width: 17rem;

      &.highlight {
        border: 2px solid colors.$secondary-red-300;
        border-radius: 0.5rem;
      }
    }
  }

  @include screenSizes.tablet {
    .label {
      P {
        @include fonts.caption1;
      }

      .reset-filters-button {
        cursor: pointer;
        @include fonts.caption1;
        color: colors.$primary-blue-400;
      }

      .download-report {
        @include fonts.caption2;
      }
    }

    .button-row {
      .filters {
        .filter-dropdown {
          width: calc(100% - 1.5rem - 2px);

          .form-field {
            @include fonts.caption2;
          }

          .options {
            top: 2.5rem;
          }
        }

        .filter-category { 
          .validated-input-message-error {
            @include fonts.caption2;
          }
        }
      }

      .date-input {
        width: 9rem;

        input {
          @include fonts.caption2;
        }
      }

      .date-range-input {
        width: 13rem;

        input {
          width: calc(100% - 1.6rem - 2px);
          @include fonts.caption2;
        }
      }

      .create-transaction {
        @include fonts.caption2;
      }
    }
  }
}